.alerts {
	position: fixed;
	z-index: 10000;
	width: 18em;
	top: 1em;
	right: 1em;
}

.alerts > div {
	padding: .8em;
	margin-bottom: .4em;
	cursor: default;
	transition: opacity .2s;
}
.alerts > div.success {
	background-color: hsla(120, 48%, 59%, 0.2);
	border: 1px solid hsla(120, 48%, 59%, 0.8);
}
.alerts > div.warn {
	background-color: hsla( 50, 48%, 59%, 0.2);
	border: 1px solid hsla( 50, 48%, 59%, 0.8);
}
.alerts > div.error {
	background-color: hsla(  0, 48%, 59%, 0.2);
	border: 1px solid hsla(  0, 48%, 59%, 0.8);
}

.alerts > * {
	white-space: pre-wrap;
}

.alerts > .alert,
.alerts > .alert-dismiss {
	opacity: 0;
}

.alerts > .alert-show {
	opacity: 1;
}
